import axios from "axios";
const ax = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    timeout: 1000000,
    params: {} // do not remove this, its added to add params later in the config
});

// Add a request interceptor
ax.interceptors.request.use(config => {
    const token = JSON.parse(localStorage.getItem("token"));
    config.headers.Authorization = "Bearer " + token;
    config.headers.post["Content-Type"] = "application/json";
    return config;
}, error => {
    console.error({ 'Request Error': error });
});
ax.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    console.error({ 'Not Authorized': error })
                    localStorage.removeItem("token");
                    window.location.href = '/';
                    break;
                case 404:
                    console.error({ 'Not Found': error })
                    break;
                case 500:
                    console.error({ 'Server Error': error })
                    //localStorage.removeItem("token");
                    //window.location.href = '/';
                    break;
                default:
            }
        }
        if (!error.response) {
            console.error({ 'Network Error': error })
            localStorage.removeItem("token");
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);
export const apiGET = (url, payload) =>
    new Promise((resolve, reject) => {
        try {
            resolve(ax.get(url, { params: { data: payload } }));
        } catch (err) {
            reject(err);
        }
    });
export const apiPOST = (url, payload, header) =>
    new Promise((resolve, reject) => {
        try {
            if (typeof header !== 'undefined') {
                resolve(ax.post(url, payload, header));
            }
            else {
                resolve(ax.post(url, payload));
            }
        } catch (err) {
            reject(err);
        }
    });
