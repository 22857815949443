//FEED
export const FEED_REQUEST = "FEED_REQUEST";
export const FEED_SUCCESS = "FEED_SUCCESS";
export const FEED_ERROR = "FEED_ERROR";

export const RESET_FEEDPOSTS = "RESET_FEEDPOSTS";

//FEEDPOST
export const ADD_FEED_POST_REQUEST = "ADD_FEED_POST_REQUEST";
export const ADD_FEED_POST_SUCCESS = "ADD_FEED_POST_SUCCESS";
export const ADD_FEED_POST_ERROR = "ADD_FEED_POST_ERROR";

export const REMOVE_FEED_POST_REQUEST = "REMOVE_FEED_POST_REQUEST";
export const REMOVE_FEED_POST_SUCCESS = "REMOVE_FEED_POST_SUCCESS";
export const REMOVE_FEED_POST_ERROR = "REMOVE_FEED_POST_ERROR";

export const ADD_FEED_POST_LIKE_REQUEST = "ADD_FEED_POST_LIKE_REQUEST";
export const ADD_FEED_POST_LIKE_ERROR = "ADD_FEED_POST_LIKE_ERROR";
export const ADD_FEED_POST_LIKE_SUCCESS = "ADD_FEED_POST_LIKE_SUCCESS";

//FEEDPOSTCOMMENT
export const FEED_POST_COMMENTS_REQUEST = "FEED_POST_COMMENTS_REQUEST";
export const FEED_POST_COMMENTS_SUCCESS = "FEED_POST_COMMENTS_SUCCESS";
export const FEED_POST_COMMENTS_ERROR = "FEED_POST_COMMENTS_ERROR";

export const ADD_FEED_POST_COMMENT_REQUEST = "ADD_FEED_POST_COMMENT_REQUEST";
export const ADD_FEED_POST_COMMENT_SUCCESS = "ADD_FEED_POST_COMMENT_SUCCESS";
export const ADD_FEED_POST_COMMENT_ERROR = "ADD_FEED_POST_COMMENT_ERROR";

export const REMOVE_FEED_POST_COMMENT_REQUEST =
  "REMOVE_FEED_POST_COMMENT_REQUEST";
export const REMOVE_FEED_POST_COMMENT_SUCCESS =
  "REMOVE_FEED_POST_COMMENT_SUCCESS";
export const REMOVE_FEED_POST_COMMENT_ERROR = "REMOVE_FEED_POST_COMMENT_ERROR";

export const ADD_FEED_POST_COMMENT_LIKE_REQUEST =
  "ADD_FEED_POST_COMMENT_LIKE_REQUEST";
export const ADD_FEED_POST_COMMENT_LIKE_SUCCESS =
  "ADD_FEED_POST_COMMENT_LIKE_SUCCESS";

//SIGNALR
export const ADD_SIGNALR_FEEDPOST = "ADD_SIGNALR_FEEDPOST";
export const REMOVE_SIGNALR_FEEDPOST = "REMOVE_SIGNALR_FEEDPOST";
export const ADD_SIGNALR_FEEDPOST_LIKE = "ADD_SIGNALR_FEEDPOST_LIKE";
export const ADD_SIGNALR_FEEDPOST_COMMENT = "ADD_SIGNALR_FEEDPOST_COMMENT";
export const ADD_SIGNALR_FEEDPOST_COMMENT_REMOVE =
  "ADD_SIGNALR_FEEDPOST_COMMENT_REMOVE";
export const ADD_SIGNALR_FEEDPOST_COMMENT_LIKE =
  "ADD_SIGNALR_FEEDPOST_COMMENT_LIKE";
