export const TRACKING_REQUEST = "TRACKING_REQUEST";
export const TRACKING_SUCCESS = "TRACKING_SUCCESS";
export const TRACKING_ERROR = "TRACKING_ERROR";

export const TRACKING_HISTORY_REQUEST = "TRACKING_HISTORY_REQUEST";
export const TRACKING_HISTORY_SUCCESS = "TRACKING_HISTORY_SUCCESS";
export const TRACKING_HISTORY_ERROR = "TRACKING_HISTORY_ERROR";

// ---- mutations only ---- //
export const TOGGLE_TRACKING_DRAWER = "TOGGLE_TRACKING_DRAWER";

