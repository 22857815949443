///* eslint-disable promise/param-names */
import {
    NOTIFICATION_REQUEST,
    NOTIFICATION_SUCCESS,
    NOTIFICATION_ERROR,
    NOTIFICATIONS_REQUEST,
    NOTIFICATIONS_SUCCESS,
    NOTIFICATIONS_ERROR,
    NOTIFICATIONSTOTAL_REQUEST,
    NOTIFICATIONSTOTAL_SUCCESS,
    NOTIFICATIONSTOTAL_ERROR,
    ADD_NOTIFICATION_FEEDBACK_REQUEST,
    ADD_NOTIFICATION_FEEDBACK_SUCCESS,
    ADD_NOTIFICATION_FEEDBACK_ERROR,
    ADD_SIGNALR_NOTIFICATION,
    TOGGLE_NOTIFICATION_MODAL
} from "../constants/notification";
import { apiGET, apiPOST } from "../../utils/api";

const state = {
    notifications: [],
    notificationsTotal: 0,
    status: "",
    statusMessage: "",
    notification: {
        open: false,
        data: null,
        status: "",
        statusMessage: ""
    },
};
const getters = {};
const actions = {
    [NOTIFICATION_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(NOTIFICATION_REQUEST);
            apiPOST("/Notification/GetById", payload)
                .then(resp => {
                    commit(NOTIFICATION_SUCCESS, resp.data);
                    resolve();
                })
                .catch(err => {
                    commit(NOTIFICATION_ERROR, err);
                    reject();
                });
        });
    },
    [NOTIFICATIONS_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(NOTIFICATIONS_REQUEST);
            apiGET("/Notification/GetByUser", payload)
                .then(resp => {
                    commit(NOTIFICATIONS_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(NOTIFICATIONS_ERROR, err);
                    reject();
                });
        });
    },
    [NOTIFICATIONSTOTAL_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(NOTIFICATIONSTOTAL_REQUEST);
            apiGET("/Notification/CountByUser", payload)
                .then(resp => {
                    commit(NOTIFICATIONSTOTAL_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(NOTIFICATIONSTOTAL_ERROR, err);
                    reject();
                });
        });
    },
    [ADD_NOTIFICATION_FEEDBACK_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(ADD_NOTIFICATION_FEEDBACK_REQUEST);
            apiPOST("/Notification/AddAnswer", payload)
                .then(resp => {
                    commit(ADD_NOTIFICATION_FEEDBACK_SUCCESS);
                    resolve();
                })
                .catch(err => {
                    commit(ADD_NOTIFICATION_FEEDBACK_ERROR, err);
                    reject();
                });
        });
    },
};
const mutations = {
    [NOTIFICATION_REQUEST]: state => {
        state.notification.status = "loading";
    },
    [NOTIFICATION_SUCCESS]: (state, resp) => {
        state.notification.data = resp;
        state.notification.status = "success";
    },
    [NOTIFICATION_ERROR]: state => {
        state.status = "error";
        state.statusMessage = "der gik noget galt med dine notificationer...";
    },
    [NOTIFICATIONS_REQUEST]: state => {
        state.status = "loading";
    },
    [NOTIFICATIONS_SUCCESS]: (state, resp) => {
        state.status = "success";
        state.notifications = resp.data;
        state.notificationsTotal = 0;
    },
    [NOTIFICATIONS_ERROR]: state => {
        state.status = "error";
        state.statusMessage = "der gik noget galt med dine notificationer...";
    },
    [NOTIFICATIONSTOTAL_REQUEST]: state => {
        state.status = "loading";
    },
    [NOTIFICATIONSTOTAL_SUCCESS]: (state, resp) => {
        state.status = "success";
        state.notificationsTotal = resp.data;
    },
    [NOTIFICATIONSTOTAL_ERROR]: state => {
        state.status = "error";
        state.statusMessage = "der gik noget galt med dine notificationer...";
    },
    [ADD_NOTIFICATION_FEEDBACK_REQUEST]: state => {
        state.notification.status = "loading";
    },
    [ADD_NOTIFICATION_FEEDBACK_SUCCESS]: (state, resp) => {
        state.notification.status = "success";
    },
    [ADD_NOTIFICATION_FEEDBACK_ERROR]: state => {
        state.notification.status = "error";
        state.notification.statusMessage = "der gik noget galt med dine notificationer...";
    },
    [ADD_SIGNALR_NOTIFICATION]: (state, resp) => {
        state.notifications.push(resp);
        state.notificationsTotal += 1;
    },
    [TOGGLE_NOTIFICATION_MODAL]: state =>
        state.notification.open ? (state.notification.open = false) : (state.notification.open = true)
};
export default {
    state,
    getters,
    actions,
    mutations
};
