///* eslint-disable promise/param-names */
import {
    USER_REQUEST,
    USER_SUCCESS,
    USER_ERROR,
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
    PROFILE_ERROR,
    UPLOAD_AVATAR_REQUEST,
    UPLOAD_AVATAR_SUCCESS,
    UPLOAD_AVATAR_ERROR,
    FOLLOW_REQUEST,
    FOLLOW_SUCCESS,
    FOLLOW_ERROR,
    FOLLOWED_USERS_REQUEST,
    FOLLOWED_USERS_SUCCESS,
    FOLLOWED_USERS_ERROR,
    UPDATE_GENERAL_REQUEST,
    UPDATE_GENERAL_SUCCESS,
    UPDATE_GENERAL_ERROR,
    UPDATE_SETTINGS_REQUEST,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_ERROR,
    UPDATE_GOAL_REQUEST,
    UPDATE_GOAL_SUCCESS,
    UPDATE_GOAL_ERROR,
    CHAT_REQUEST,
    CHAT_SUCCESS,
    CHAT_ERROR,
    ADD_CHAT_REQUEST,
    ADD_CHAT_SUCCESS,
    ADD_CHAT_ERROR,
    ADD_SIGNALR_CHAT_MESSAGE,
    TOGGLE_PROFILE_DRAWER,
    TOGGLE_PROFILE_EDIT_DRAWER,
    TOGGLE_CHAT_DRAWER,
    TOGGLE_DELETE_ACCOUNT_MODAL,
    TOGGLE_CHANGE_PASSWORD_MODAL,
    TOGGLE_TOUR_MODAL,
    TOGGLE_PAYMENT_MODAL
} from "../constants/user";
import { apiGET, apiPOST } from "../../utils/api";
const state = {
    data: null,
    profile: {
        data: null,
        followed: false,
        status: "",
        statusMessage: ""
    },
    chat: {
        data: null,
        totalMessages: 0,
        status: "",
        statusMessage: ""
    },
    followedUsers: {
        data: [],
        status: "",
        statusMessage: ""
    },
    drawers: {
        profile: {
            open: false
        },
        profileEdit: {
            open: false
        },
        chat: {
            open: false
        }
    },
    modals: {
        payment: {
            open: false,
            isForced: true
        },
        tour: {
            open: false
        },
        deleteAccount: {
            open: false
        },
        changePassword: {
            open: false
        }
    },
    status: "",
    statusMessage: ""
};
const getters = {
    // ---- subscriptiontypes ---- //
    isLimitless: state => (state.data.subscriptionType === 1 ? true : false),
    isTrial: state => (state.data.subscriptionType === 2 ? true : false),
    isRestricted: state => (state.data.subscriptionType === 3 ? true : false),
    isPaid: state => (state.data.subscriptionType === 4 ? true : false),

    // ---- roletypes ---- //
    isDeveloper: state => (state.data.roleType == 1 ? true : false),
    isAdmin: state => (state.data.roleType == 2 ? true : false),
    isPresigner: state => (state.data.roleType == 3 ? true : false),
    isMember: state => (state.data.roleType == 4 ? true : false)
};
const actions = {
    [USER_REQUEST]: ({ commit }) => {
        return new Promise((resolve) => {
            commit(USER_REQUEST);
            apiGET("/User/Get")
                .then(resp => {
                    commit(USER_SUCCESS, resp.data);
                    resolve(resp.data);
                })
                .catch(err => {
                    commit(USER_ERROR, err);
                });
        });
    },
    [PROFILE_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(PROFILE_REQUEST);
            apiGET("/User/GetProfile", payload)
                .then(resp => {
                    commit(PROFILE_SUCCESS, resp.data);
                    resolve(resp.data);
                })
                .catch(err => {
                    commit(PROFILE_ERROR, err);
                    reject(err);
                });
        });
    },
    [FOLLOWED_USERS_REQUEST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit(FOLLOWED_USERS_REQUEST);
            apiGET("/User/GetFollowedUsers")
                .then(resp => {
                    commit(FOLLOWED_USERS_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(FOLLOWED_USERS_ERROR, err);
                    reject();
                });
        });
    },
    [FOLLOW_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(FOLLOW_REQUEST);
            apiPOST("/User/Follow", payload)
                .then(resp => {
                    commit(FOLLOW_SUCCESS, resp.data);
                    resolve();
                })
                .catch(err => {
                    commit(FOLLOW_ERROR, err);
                    reject();
                });
        });
    },
    // ---- edit profile ---- //
    [UPLOAD_AVATAR_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            apiPOST("/User/UpdateProfileImage", payload, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(resp => {
                    commit(UPLOAD_AVATAR_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    reject();
                });
        });
    },
    [UPDATE_GENERAL_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            apiPOST("/User/UpdateGeneral", payload)
                .then(resp => {
                    resolve();
                })
                .catch(err => {
                    reject();
                });
        });
    },
    [UPDATE_SETTINGS_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            apiPOST("/User/UpdateSettings", payload)
                .then(resp => {
                    resolve();
                })
                .catch(err => {
                    reject();
                });
        });
    },
    [UPDATE_GOAL_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            apiPOST("/User/UpdateGoal", payload)
                .then(resp => {
                    resolve();
                })
                .catch(err => {
                    reject();
                });
        });
    },
    // ---- chat ---- //
    [CHAT_REQUEST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit(CHAT_REQUEST);
            apiPOST("/Chat/GetMessages")
                .then(resp => {
                    commit(CHAT_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(CHAT_ERROR, err);
                    reject();
                });
        });
    },
    [ADD_CHAT_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(ADD_CHAT_REQUEST);
            apiPOST("/Chat/Send", payload)
                .then(resp => {
                    commit(ADD_CHAT_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(ADD_CHAT_ERROR, err);
                    reject();
                });
        });
    }
};
const mutations = {
    // ---- get user ---- //
    [USER_REQUEST]: state => {
        state.status = "loading";
    },
    [USER_SUCCESS]: (state, resp) => {
        state.data = resp;
        state.chat.totalMessages = resp.chatMessagesTotal;
        state.status = "success";
    },
    [USER_ERROR]: state => {
        state.status = "error";
        state.statusMessage = "der er sket en fejl...";
    },

    // ---- get profile ---- //
    [PROFILE_REQUEST]: state => {
        state.profile.status = "loading";
    },
    [PROFILE_SUCCESS]: (state, resp) => {
        state.profile.data = resp;
        if (state.followedUsers.data.filter(x => x.id == state.profile.data.id).length > 0) {
            state.profile.followed = true;
        }
        else {
            state.profile.followed = false;
        }
        state.profile.status = "success";
    },
    [PROFILE_ERROR]: state => {
        state.profile.status = "error";
        state.profile.statusMessage = "Vi kunne ikke finde denne profil...";
    },

    // ---- get followed_users ---- //
    [FOLLOWED_USERS_REQUEST]: state => {
        state.followedUsers.status = "loading";
    },
    [FOLLOWED_USERS_SUCCESS]: (state, resp) => {
        state.followedUsers.status = "success";
        state.followedUsers.data = resp.data;
    },
    [FOLLOWED_USERS_ERROR]: state => {
        state.followedUsers.status = "error";
    },
    [UPLOAD_AVATAR_REQUEST]: (state, resp) => { },
    [UPLOAD_AVATAR_SUCCESS]: (state, resp) => {
        state.data.image = resp.data;
    },
    [UPLOAD_AVATAR_ERROR]: state => {
        state.statusMessage = "der er sket en fejl...";
    },
    // ---- chat ---- //
    [CHAT_REQUEST]: state => {
        state.chat.status = "loading";
    },
    [CHAT_SUCCESS]: (state, resp) => {
        state.chat.data = resp.data;
        state.chat.totalMessages = 0;
        state.chat.status = "success";
    },
    [CHAT_ERROR]: state => {
        state.chat.status = "error";
        state.chat.statusMessage = "der er sket en fejl...";
    },

    [ADD_CHAT_REQUEST]: state => {
        state.chat.status = "loading";
    },
    [ADD_CHAT_SUCCESS]: (state, resp) => {
        state.chat.data.push(resp.data);
        state.chat.status = "success";
    },
    [ADD_CHAT_ERROR]: state => {
        state.chat.status = "error";
        state.chat.statusMessage = "der er sket en fejl...";
    },
    // ---- chatSignalR ---- //
    [ADD_SIGNALR_CHAT_MESSAGE]: (state, resp) => {
        if (!state.drawers.chat.open) {
            state.chat.totalMessages += 1;
        } else {
            state.chat.data.push(resp);
            apiPOST("/Chat/MarkAsRead")
                .then(resp => { })
                .catch(err => { });
        }
    },

    [FOLLOW_REQUEST]: state => { },
    [FOLLOW_SUCCESS]: (state, resp) => {
        if (resp.isAdded == true) {
            var index = state.followedUsers.data.findIndex(
                x => x.id == resp.followedUserId
            );
            state.followedUsers.data.splice(index, 1);
            state.profile.followed = false;
        } else {
            state.followedUsers.data.push(resp.followed);
            state.profile.followed = true;
        }
    },
    [FOLLOW_ERROR]: state => { },

    // ---- drawers ---- //
    [TOGGLE_PROFILE_DRAWER]: state =>
        state.drawers.profile.open
            ? (state.drawers.profile.open = false)
            : (state.drawers.profile.open = true),
    [TOGGLE_PROFILE_EDIT_DRAWER]: state =>
        state.drawers.profileEdit.open
            ? (state.drawers.profileEdit.open = false)
            : (state.drawers.profileEdit.open = true),
    [TOGGLE_CHAT_DRAWER]: state =>
        state.drawers.chat.open
            ? (state.drawers.chat.open = false)
            : (state.drawers.chat.open = true),

    // ---- modals ----//
    [TOGGLE_CHANGE_PASSWORD_MODAL]: state =>
        state.modals.changePassword.open
            ? (state.modals.changePassword.open = false)
            : (state.modals.changePassword.open = true),
    [TOGGLE_DELETE_ACCOUNT_MODAL]: state =>
        state.modals.deleteAccount.open
            ? (state.modals.deleteAccount.open = false)
            : (state.modals.deleteAccount.open = true),
    [TOGGLE_TOUR_MODAL]: state =>
        state.modals.tour.open
            ? (state.modals.tour.open = false)
            : (state.modals.tour.open = true),
    [TOGGLE_PAYMENT_MODAL]: state =>
        state.modals.payment.open
            ? (state.modals.payment.open = false)
            : (state.modals.payment.open = true)
};
export default {
    state,
    actions,
    getters,
    mutations
};
