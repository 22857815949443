///* eslint-disable promise/param-names */
import {
    ADD_FLAG_REQUEST,
    ADD_FLAG_SUCCESS,
    ADD_FLAG_ERROR,
    CLOSE_FLAG,
    OPEN_FLAG
} from "../constants/flag";
import { apiPOST } from "../../utils/api";

const state = {
    status: "",
    open: false,
    referenceId: null,
    type: null
};
const getters = {};
const actions = {
    [ADD_FLAG_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(ADD_FLAG_REQUEST);
            apiPOST("/Reporting/Add", payload)
                .then(resp => {
                    commit(ADD_FLAG_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(ADD_FLAG_ERROR, err);
                    reject();
                });
        });
    }
};
const mutations = {
    [ADD_FLAG_REQUEST]: state => {
        state.status = "loading";
    },
    [ADD_FLAG_SUCCESS]: (state, resp) => {
        state.status = "success";
        state.referenceId = null;
        state.type = null;
        state.open = false;
    },
    [ADD_FLAG_ERROR]: (state, err) => {
        state.status = "error";
    },
    [OPEN_FLAG]: (state, resp) => {
        state.referenceId = resp.referenceId;
        state.type = resp.type;
        state.open = true;
    },
    [CLOSE_FLAG]: (state, resp) => {
        state.open = false;
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};
