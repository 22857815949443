///* eslint-disable promise/param-names */
import {
    TRACKING_REQUEST,
    TRACKING_SUCCESS,
    TRACKING_ERROR,
    TRACKING_HISTORY_REQUEST,
    TRACKING_HISTORY_SUCCESS,
    TRACKING_HISTORY_ERROR,
    TOGGLE_TRACKING_DRAWER,
    SET_TRACKING_ALLOWED
} from "../constants/tracker";
import { apiGET, apiPOST } from "../../utils/api";

const state = {
    status: "",
    statusMessage: "",
    currentWeight: null,
    tracking: {
        open: false,
        history: {
            data: null
        }
    }
};
const getters = {};
const actions = {
    [TRACKING_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(TRACKING_REQUEST);
            apiPOST("/Tracking/Add", payload, {
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(resp => {
                    commit(TRACKING_SUCCESS, resp);

                    resolve();
                })
                .catch(err => {
                    commit(TRACKING_ERROR, err);
                    reject();
                });
        });
    },
    [TRACKING_HISTORY_REQUEST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit(TRACKING_HISTORY_REQUEST);
            apiGET("/Tracking/Get-All")
                .then(resp => {
                    commit(TRACKING_HISTORY_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(TRACKING_HISTORY_ERROR, err);
                    reject();
                });
        });
    }
};
const mutations = {
    // ---- add tracking ---- //
    [TRACKING_REQUEST]: state => {
        state.status = "loading";
    },
    [TRACKING_SUCCESS]: (state, resp) => {
        state.status = "success";
    },
    [TRACKING_ERROR]: state => {
        state.status = "error";
        state.statusMessage = "der er sket en fejl...";
    },

    // ---- get tracking history ---- //
    [TRACKING_HISTORY_REQUEST]: state => {
        state.status = "loading";
    },
    [TRACKING_HISTORY_SUCCESS]: (state, resp) => {
        state.tracking.history.data = resp.data;

        state.status = "success";
    },
    [TRACKING_HISTORY_ERROR]: state => {
        state.status = "error";
        state.statusMessage = "der er sket en fejl...";
    },

    // ---- tracking prop ---- //
    [TOGGLE_TRACKING_DRAWER]: state =>
        state.tracking.open
            ? (state.tracking.open = false)
            : (state.tracking.open = true),


};
export default {
    state,
    getters,
    actions,
    mutations
};
