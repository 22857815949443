import { Workbox } from "workbox-window";
import { MessageBox } from "element-ui";
let wb;
if ("serviceWorker" in navigator) {
    wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

    wb.addEventListener("installed", event => {
        console.log('Installed')
        if (event.isUpdate) {
            window.location.reload();
            //MessageBox.confirm(
            //    "Der er findes en opdatering til coachify som vi gerne vil have du henter.",
            //    "Opdatering",
            //    {
            //        confirmButtonText: "Opdater",
            //        cancelButtonText: "Luk",
            //        type: "warning"
            //    }
            //)
            //    .then(() => {
            //        window.location.reload();
            //    })
            //    .catch(() => { });
        }
    });
    wb.register();
} else {
    wb = null;
}

export default wb;
