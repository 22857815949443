import {
  SIGNUP_REQUEST,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  SIGNUP_EMAIL_REQUEST,
  SIGNUP_EMAIL_ERROR,
  SIGNUP_EMAIL_SUCCESS
} from "../constants/signup";
import { apiPOST } from "../../utils/api";

const state = {
  status: "",
  statusMessage: ""
};

const getters = {};

const actions = {
  [SIGNUP_REQUEST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(SIGNUP_REQUEST);
      apiPOST("/Account/Create", payload)
        .then(resp => {
          resolve(resp);
          commit(SIGNUP_SUCCESS, resp);
        })
        .catch(err => {
          reject(err);
          commit(SIGNUP_ERROR, err);
        });
    });
  },
  [SIGNUP_EMAIL_REQUEST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(SIGNUP_EMAIL_REQUEST);
      apiPOST("/Account/CheckEmail", payload)
        .then(resp => {
          commit(SIGNUP_EMAIL_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(SIGNUP_EMAIL_ERROR, err);
          reject(err);
        });
    });
  }
};

const mutations = {
  //Signup
  [SIGNUP_REQUEST]: state => {
    state.status = "loading";
  },
  [SIGNUP_SUCCESS]: state => {
    state.status = "success";
    state.statusMessage =
      "Du vil indenfor kort tid modtage en mail hvor du skal bekræfte din mail og oprette din nye kode...";
  },
  [SIGNUP_ERROR]: state => {
    state.status = "error";
    state.statusMessage =
      "Ooops, vi beklager :( der gik noget glat, prøv igen senere, ellers kontakt vores support afdeling...";
  },
  //EmailCheck
  [SIGNUP_EMAIL_REQUEST]: state => {},
  [SIGNUP_EMAIL_SUCCESS]: (state, resp) => {},
  [SIGNUP_EMAIL_ERROR]: (state, resp) => {}
};

export default {
  state,
  getters,
  actions,
  mutations
};
