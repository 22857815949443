<template>
    <el-container id="dashboard" v-if="user">
        <section class="container-inner" id="diet">
            <section id="tracker">
                <div class="section-header">
                    <h5>Status</h5>
                    <el-button type="default" @click="openTracking" icon="el-icon-data-line" size="small" plain v-if="user.goalId != 3">
                        Måling
                    </el-button>
                </div>
                <tracker />
            </section>

                <recipe-plan />

        </section>
        <section class="container-inner" id="feed" v-if="$screen.lg">
            <section id="feed-posts">
                <div class="section-header">
                    <h5 class="section-title">Opslag</h5>
                </div>
                <feed-post-add v-if="!isRestricted" />
                <div v-infinite-scroll="getFeedposts"
                     infinite-scroll-distance="30"
                     infinite-scroll-disabled="disabled">
                    <feed-post v-for="(post, index) in feedposts"
                               :key="index"
                               :data="post" />
                </div>
            </section>
        </section>
        <section class="container-inner" id="following" v-if="$screen.lg && followedUsers.length != 0">
            <section id="followed-users">
                <followed-users />
            </section>
        </section>
        <div v-if="user">
            <!-- Drawers -->
            <recipes />
            <recipe />
            <groceries />
            <tracking />
        </div>
    </el-container>
</template>
<style lang="scss">
    @import "../assets/styles/pages/dashboard";
</style>
<script>

    // --- store --- //
    import { mapState, mapGetters } from "vuex";
    import { TOGGLE_PAYMENT_MODAL } from "../store/constants/user";
    import { TOGGLE_TRACKING_DRAWER } from "../store/constants/tracker";

    import { FEED_REQUEST, RESET_FEEDPOSTS } from "../store/constants/feed";

    export default {
        name: "dashboard",
        components: {
            'tracker': () => import(/* webpackChunkName: "tracker" */ '../components/tracking/tracker'),
            'recipe-plan': () => import(/* webpackChunkName: "recipe-plan" */ '../components/recipe/recipe_plan'),
            'recipes': () => import(/* webpackChunkName: "recipes" */ '../components/recipe/recipes'),
            'recipe': () => import(/* webpackChunkName: "recipe" */ '../components/recipe/recipe'),
            'tracking': () => import(/* webpackChunkName: "tracking" */ '../components/tracking/tracking'),
            'groceries': () => import(/* webpackChunkName: "groceries" */ '../components/groceries/groceries'),
            'feed-post': () => import(/* webpackChunkName: "feed" */ '../components/feed/feed_post'),
            'feed-post-add': () => import(/* webpackChunkName: "feed" */ '../components/feed/feed_post_add'),
            'followed-users': () => import(/* webpackChunkName: "followed" */ '../components/following/followed_users'),
        },
        data() {
            return {
                disabled: false,
                feed: {
                    pageSize: 5,
                    pageNo: 0
                }
            };
        },
        created() {
            this.$store.commit(RESET_FEEDPOSTS);
        },
        methods: {

            openTracking() {
                if (this.isRestricted) {
                    this.$store.commit(TOGGLE_PAYMENT_MODAL);
                } else {
                    this.$store.commit(TOGGLE_TRACKING_DRAWER);
                }
            },
            getFeedposts() {
                if (!this.disabled) {
                    this.disabled = true;
                    if (!this.$screen.lg) {
                        this.feed.pageSize = 3;
                    }
                    this.$store
                        .dispatch(FEED_REQUEST, {
                            pageNo: this.feed.pageNo,
                            pageSize: this.feed.pageSize
                        })
                        .then(resp => {
                            if (resp.length) {
                                this.feed.pageNo += 1;
                                this.disabled = false;
                            } else {
                                this.disabled = true;
                            }
                        });
                }
            }
        },
        computed: {
            ...mapState({
                user: state => state.user.data,
                feedposts: state => state.feed.feedPosts,
                followedUsers: state => state.user.followedUsers.data
            }),
            ...mapGetters({
                isRestricted: "isRestricted"
            })
        }
    };
</script>
