///* eslint-disable promise/param-names */
import {
  FEED_REQUEST,
  FEED_SUCCESS,
  FEED_ERROR,
  ADD_FEED_POST_REQUEST,
  ADD_FEED_POST_SUCCESS,
  ADD_FEED_POST_ERROR,
  REMOVE_FEED_POST_REQUEST,
  REMOVE_FEED_POST_SUCCESS,
  REMOVE_FEED_POST_ERROR,
  FEED_POST_COMMENTS_REQUEST,
  FEED_POST_COMMENTS_SUCCESS,
  FEED_POST_COMMENTS_ERROR,
  ADD_FEED_POST_COMMENT_REQUEST,
  ADD_FEED_POST_COMMENT_SUCCESS,
  ADD_FEED_POST_COMMENT_ERROR,
  REMOVE_FEED_POST_COMMENT_REQUEST,
  ADD_FEED_POST_LIKE_REQUEST,
  ADD_FEED_POST_LIKE_SUCCESS,
  ADD_FEED_POST_LIKE_ERROR,
  ADD_SIGNALR_FEEDPOST,
  ADD_SIGNALR_FEEDPOST_LIKE,
  ADD_SIGNALR_FEEDPOST_COMMENT,
  ADD_SIGNALR_FEEDPOST_COMMENT_REMOVE,
  ADD_SIGNALR_FEEDPOST_COMMENT_LIKE,
  REMOVE_SIGNALR_FEEDPOST,
  ADD_FEED_POST_COMMENT_LIKE_REQUEST,
  ADD_FEED_POST_COMMENT_LIKE_SUCCESS,
  RESET_FEEDPOSTS
} from "../constants/feed";
import { apiGET, apiPOST, apiPUT } from "../../utils/api";

const state = {
  feedPosts: [],
  status: "",
  statusMessage: ""
};
const getters = {};
const actions = {
  [FEED_REQUEST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(FEED_REQUEST, payload);
      apiPOST("/Feed/GetPosts", payload)
        .then(resp => {
          commit(FEED_SUCCESS, resp);
          resolve(resp.data);
        })
        .catch(err => {
          commit(FEED_ERROR, err);
          reject();
        });
    });
  },
  [ADD_FEED_POST_REQUEST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(ADD_FEED_POST_REQUEST);
      apiPOST("/Feed/AddPost", payload)
        .then(resp => {
          commit(ADD_FEED_POST_SUCCESS, resp);
          resolve();
        })
        .catch(err => {
          commit(ADD_FEED_POST_ERROR, err);
          reject();
        });
    });
  },
  [REMOVE_FEED_POST_REQUEST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(REMOVE_FEED_POST_REQUEST);
      apiPOST("/Feed/RemovePost", payload)
        .then(resp => {
          commit(REMOVE_FEED_POST_SUCCESS, resp);
          resolve();
        })
        .catch(err => {
          commit(REMOVE_FEED_POST_ERROR, err);
          reject();
        });
    });
  },
  [FEED_POST_COMMENTS_REQUEST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(FEED_POST_COMMENTS_REQUEST);
      apiGET("/Feed/GetComments", payload)
        .then(resp => {
          commit(FEED_POST_COMMENTS_SUCCESS, resp.data);
          resolve();
        })
        .catch(err => {
          commit(FEED_POST_COMMENTS_ERROR, err);
          reject();
        });
    });
  },
  [ADD_FEED_POST_COMMENT_REQUEST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(FEED_POST_COMMENTS_REQUEST);
      apiPOST("/Feed/AddComment", payload)
        .then(resp => {
          commit(FEED_POST_COMMENTS_SUCCESS, resp);
          resolve();
        })
        .catch(err => {
          commit(FEED_POST_COMMENTS_ERROR, err);
          reject();
        });
    });
  },
  [ADD_FEED_POST_LIKE_REQUEST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiPOST("/Feed/LikePost", payload)
        .then(resp => {
          commit(ADD_FEED_POST_LIKE_SUCCESS, payload);
          resolve();
        })
        .catch(err => {
          reject();
        });
    });
  },
  [REMOVE_FEED_POST_COMMENT_REQUEST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiPOST("/Feed/RemoveComment", payload)
        .then(resp => {
          resolve();
        })
        .catch(err => {
          reject();
        });
    });
  },
  [ADD_FEED_POST_COMMENT_LIKE_REQUEST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apiPOST("/Feed/LikeComment", payload)
        .then(resp => {
          commit(ADD_FEED_POST_COMMENT_LIKE_SUCCESS, resp);
          resolve();
        })
        .catch(err => {
          reject();
        });
    });
  }
};
const mutations = {
  [FEED_REQUEST]: state => {},
  [FEED_SUCCESS]: (state, resp) => {
    state.feedPosts = state.feedPosts.concat(resp.data);
  },
  [FEED_ERROR]: state => {},
  //FEEDPOST
  [REMOVE_FEED_POST_REQUEST]: state => {},
  [REMOVE_FEED_POST_ERROR]: state => {},
  [REMOVE_FEED_POST_SUCCESS]: (state, resp) => {},
  [ADD_FEED_POST_REQUEST]: state => {},
  [ADD_FEED_POST_SUCCESS]: (state, resp) => {},
  [ADD_FEED_POST_ERROR]: state => {},
  [FEED_POST_COMMENTS_REQUEST]: state => {},
  [FEED_POST_COMMENTS_SUCCESS]: (state, resp) => {
    if (resp.length > 0) {
      let feedpost = state.feedPosts.find(x => x.id === resp[0].postId);
      feedpost.comments = resp;
    }
  },
  [FEED_POST_COMMENTS_ERROR]: state => {},
  [ADD_FEED_POST_LIKE_SUCCESS]: (state, resp) => {
    let feedpost = state.feedPosts.find(x => x.id === resp);
    if (feedpost.hasLiked) {
      feedpost.hasLiked = false;
    } else {
      feedpost.hasLiked = true;
    }
  },
  [ADD_FEED_POST_COMMENT_LIKE_SUCCESS]: (state, resp) => {
    let feedpost = state.feedPosts.find(x => x.id === resp.data.postId);
    let comment = feedpost.comments.find(x => x.id === resp.data.commentId);
    if (comment.hasLiked) {
      comment.hasLiked = false;
    } else {
      comment.hasLiked = true;
    }
  },
  //Signalr
  [ADD_SIGNALR_FEEDPOST]: (state, resp) => {
    state.feedPosts.unshift(resp);
  },
  [ADD_SIGNALR_FEEDPOST_LIKE]: (state, resp) => {
    state.feedPosts.find(x => x.id === resp.postId).likes = resp.likes;
  },
  [ADD_SIGNALR_FEEDPOST_COMMENT_LIKE]: (state, resp) => {
    let feedpost = state.feedPosts.find(x => x.id === resp.postId);
    feedpost.comments.find(x => x.id === resp.commentId).likes =
      resp.commentLikes;
  },
  [ADD_SIGNALR_FEEDPOST_COMMENT]: (state, resp) => {
    let feedpost = state.feedPosts.find(x => x.id === resp.comment.postId);
    feedpost.commentsTotal = resp.commentsTotal;
    feedpost.comments.push(resp.comment);
  },
  [ADD_SIGNALR_FEEDPOST_COMMENT_REMOVE]: (state, resp) => {
    let feedpost = state.feedPosts.find(x => x.id === resp.postId);
    feedpost.commentsTotal = resp.commentsTotal;
    let commentIndex = feedpost.comments.findIndex(
      x => x.id === resp.commentId
    );
    feedpost.comments.splice(commentIndex, 1);
  },
  [REMOVE_SIGNALR_FEEDPOST]: (state, resp) => {
    let feedpostIndex = state.feedPosts.findIndex(x => x.id === resp);
    state.feedPosts.splice(feedpostIndex, 1);
  },

  // ---- reset state ---- //
  [RESET_FEEDPOSTS]: state => {
    state.feedPosts = [];
    state.status = "reset";
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
