<template>
    <el-container>
        <el-main id="app">
            <authorized v-if="isAuthenticated && !isPaymentReceipt" />
            <authenticate v-if="!isAuthenticated && !isPaymentReceipt" />
            <loading v-if="isPaymentReceipt" />
        </el-main>
    </el-container>
</template>
<style lang="scss">
    @import "./assets/styles/_global.scss";

</style>
<script>
    // --- store --- //
    import { mapState } from "vuex";

    export default {
        components: {
            'authorized': () => import(/* webpackChunkName: "authorized" */ './pages/authorized'),
            'authenticate': () => import(/* webpackChunkName: "authenticate" */ './pages/authenticate'),
            'loading': () => import(/* webpackChunkName: "loading" */ './pages/loading'),
        },
        name: "app",
        computed: {
            ...mapState({
                isAuthenticated: state => !!state.auth.token
            }),
            isPaymentReceipt() {
                return this.$route.path == "/payment/ok";
            }
        }
    };
</script>
