export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";

export const NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";

export const NOTIFICATIONSTOTAL_REQUEST = "NOTIFICATIONSTOTAL_REQUEST";
export const NOTIFICATIONSTOTAL_SUCCESS = "NOTIFICATIONSTOTAL_SUCCESS";
export const NOTIFICATIONSTOTAL_ERROR = "NOTIFICATIONSTOTAL_ERROR";

export const ADD_NOTIFICATION_FEEDBACK_REQUEST = "ADD_NOTIFICATION_FEEDBACK_REQUEST";
export const ADD_NOTIFICATION_FEEDBACK_SUCCESS = "ADD_NOTIFICATION_FEEDBACK_SUCCESS";
export const ADD_NOTIFICATION_FEEDBACK_ERROR = "ADD_NOTIFICATION_FEEDBACK_ERROR";

export const ADD_SIGNALR_NOTIFICATION = "ADD_SIGNALR_NOTIFICATION";

export const TOGGLE_NOTIFICATION_MODAL = "TOGGLE_NOTIFICATION_MODAL";