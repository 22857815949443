export const numbers = {
    da: {
        currency: {
            style: 'currency',
            currency: 'USD'
        },
        decimal: {
            minimumFractionDigits: 0,
        },
    }
}
