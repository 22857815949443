///* eslint-disable promise/param-names */
import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    CLOSE_ACCOUNT_REQUEST,
    CLOSE_ACCOUNT_SUCCESS,
    CLOSE_ACCOUNT_ERROR,
    SUBSCRIPTION_REQUEST,
    SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_ERROR,
    SUBSCRIPTION_REACTIVATE_REQUEST,
    SUBSCRIPTION_REACTIVATE_SUCCESS,
    SUBSCRIPTION_REACTIVATE_ERROR,
    CLOSE_SUBSCRIPTION_REQUEST,
    CLOSE_SUBSCRIPTION_SUCCESS,
    SET_STATE_TOKEN
} from "../constants/auth";
import { apiPOST } from "../../utils/api";

const state = {
    token: JSON.parse(localStorage.getItem("token")) || "",
    status: "",
    statusMessage: "",
    subscription: {
        data: null,
        status: "",
        statusMessage: "",
    }
};

const getters = {
    isAuthenticated: state => !!state.token
};

const actions = {
    [AUTH_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            apiPOST("/Account/Login", payload)
                .then(resp => {
                    commit(AUTH_SUCCESS, resp);
                    resolve(resp.data);
                })
                .catch(err => {
                    commit(AUTH_ERROR, err);
                    reject(err);
                });
        });
    },
    [AUTH_LOGOUT]: ({ commit, rootState }) => {
        return new Promise(resolve => {
            commit(AUTH_LOGOUT, rootState.user);
            resolve();
        });
    },
    [FORGOT_PASSWORD_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(FORGOT_PASSWORD_REQUEST);
            apiPOST("/Account/ForgotPassword", payload)
                .then(resp => {
                    commit(FORGOT_PASSWORD_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(FORGOT_PASSWORD_ERROR, err);
                    reject();
                });
        });
    },
    [RESET_PASSWORD_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            //commit(RESET_PASSWORD_REQUEST);
            apiPOST("/Account/ResetPassword", payload)
                .then(resp => {
                    localStorage.setItem("token", JSON.stringify(resp.data));
                    //commit(RESET_PASSWORD_SUCCESS, resp);
                    commit(AUTH_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    //commit(RESET_PASSWORD_ERROR, err);
                    reject();
                });
        });
    },
    [CHANGE_PASSWORD_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            apiPOST("/Account/ChangePassword", payload)
                .then(resp => {
                    //commit(CHANGE_PASSWORD_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    //commit(CHANGE_PASSWORD_ERROR, err);
                    reject();
                });
        });
    },
    [SUBSCRIPTION_REQUEST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit(SUBSCRIPTION_REQUEST);
            apiPOST("/Payment/GetCurrentSubscription")
                .then(resp => {
                    commit(SUBSCRIPTION_SUCCESS, resp.data);
                    resolve();
                })
                .catch(err => {
                    commit(SUBSCRIPTION_ERROR, err);
                    reject();
                });
        });
    },
    [SUBSCRIPTION_REACTIVATE_REQUEST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit(SUBSCRIPTION_REACTIVATE_REQUEST);
            apiPOST("/Payment/ReactivateSubscription")
                .then(resp => {
                    commit(SUBSCRIPTION_REACTIVATE_SUCCESS, resp.data);
                    console.log(resp.data)
                    resolve();
                })
                .catch(err => {
                    commit(SUBSCRIPTION_REACTIVATE_ERROR, err);
                    reject();
                });
        });
    },
    [CLOSE_ACCOUNT_REQUEST]: ({ commit, rootState }, payload) => {
        return new Promise((resolve, reject) => {
            apiPOST("/Account/Delete", payload)
                .then(resp => {
                    commit(CLOSE_ACCOUNT_SUCCESS, rootState.user);
                    resolve();
                })
                .catch(err => {
                    commit(CLOSE_ACCOUNT_ERROR, err);
                    reject();
                });
        });
    },
    [CLOSE_SUBSCRIPTION_REQUEST]: ({ commit }) => {
        commit(CLOSE_SUBSCRIPTION_REQUEST);
        return new Promise((resolve, reject) => {
            apiPOST("/Payment/DeleteSubscription")
                .then(resp => {
                    commit(CLOSE_SUBSCRIPTION_SUCCESS);
                    resolve();
                })
                .catch(err => {
                    reject();
                });
        });
    }
};
const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, resp) => {
        localStorage.setItem("token", JSON.stringify(resp.data));
        state.token = resp.data;
        state.status = "success";
    },
    [AUTH_ERROR]: state => {
        state.status = "error";
        state.statusMessage = "Ooops, der gik noget galt, tjek lige om dine oplysninger er korrekte...";
    },
    [AUTH_LOGOUT]: (state, rootStateUser) => {
        localStorage.removeItem("token");
        state.token = "";
        rootStateUser.data = null;
        rootStateUser.profile.data = null;
    },
    [FORGOT_PASSWORD_REQUEST]: state => {
        state.status = "loading";
    },
    [FORGOT_PASSWORD_SUCCESS]: state => {
        state.status = "success";
        state.statusMessage =
            "Du vil indenfor kort tid modtage en mail med et link hvor du kan nulstille dit password...";
    },
    [FORGOT_PASSWORD_ERROR]: state => {
        state.status = "error";
    },
    [SUBSCRIPTION_REQUEST]: state => {
        state.subscription.status = "loading";
    },
    [SUBSCRIPTION_SUCCESS]: (state, resp) => {
        state.subscription.status = "success";
        state.subscription.data = resp
    },
    [SUBSCRIPTION_ERROR]: state => {
        state.subscription.status = "error";
    },
    [SUBSCRIPTION_REACTIVATE_REQUEST]: state => {
        state.subscription.status = "loading";
    },
    [SUBSCRIPTION_REACTIVATE_SUCCESS]: (state, resp) => {
        state.subscription.status = "success";
    },
    [SUBSCRIPTION_REACTIVATE_ERROR]: state => {
        state.subscription.status = "error";
    },
    [CLOSE_SUBSCRIPTION_REQUEST]: state => {
        state.subscription.status = "loading";
    },
    [CLOSE_SUBSCRIPTION_SUCCESS]: state => {
        state.subscription.status = "success";
    },
    [CLOSE_ACCOUNT_SUCCESS]: (state, resp) => {
        localStorage.removeItem("token");
        state.token = "";
        localStorage.removeItem("dashboard_tour");
        resp.data = null;

    },
    [CLOSE_ACCOUNT_ERROR]: (state, resp) => {
        state.status = "error";
    },
    [SET_STATE_TOKEN]: (state, resp) => {
        localStorage.setItem("token", JSON.stringify(resp));
        state.token = resp;
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};
