///* eslint-disable promise/param-names */
import {
    DIETPLAN_REQUEST,
    DIETPLAN_SUCCESS,
    DIETPLAN_ERROR,
    DIETPLAN_SHUFFLE_REQUEST,
    DIETPLAN_SHUFFLE_SUCCESS,
    DIETPLAN_SHUFFLE_ERROR,
    ADD_RECIPE_REQUEST,
    ADD_RECIPE_SUCCESS,
    ADD_RECIPE_ERROR,
    REMOVE_RECIPE_REQUEST,
    REMOVE_RECIPE_SUCCESS,
    REMOVE_RECIPE_ERROR
} from "../constants/dietplan";
import { apiGET, apiPOST } from "../../utils/api";

const state = {
    status: "",
    statusMessage: "",
    plan: [{}, {}, {}, {}, {}, {}]
};
const getters = {};
const actions = {
    [DIETPLAN_REQUEST]: ({ commit }, payload) => {
        commit(DIETPLAN_REQUEST);
        return new Promise((resolve, reject) => {
            apiPOST("/Diet/GetByUser", payload)
                .then(resp => {
                    commit(DIETPLAN_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(DIETPLAN_ERROR, err);
                    reject();
                });
        });
    },
    [DIETPLAN_SHUFFLE_REQUEST]: ({ commit }, payload) => {
        commit(DIETPLAN_SHUFFLE_REQUEST);
        return new Promise((resolve, reject) => {
            apiPOST("/Diet/Randomize", payload)
                .then(resp => {
                    commit(DIETPLAN_SHUFFLE_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(DIETPLAN_SHUFFLE_ERROR, err);
                    reject();
                });
        });
    },
    [ADD_RECIPE_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(ADD_RECIPE_REQUEST);
            apiPOST("/Diet/AddRecipe", payload)
                .then(resp => {
                    commit(ADD_RECIPE_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(ADD_RECIPE_ERROR, err);
                    reject();
                });
        });
    },
    [REMOVE_RECIPE_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(REMOVE_RECIPE_REQUEST);
            apiPOST("/Diet/RemoveRecipe", payload)
                .then(resp => {
                    commit(REMOVE_RECIPE_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(REMOVE_RECIPE_ERROR, err);
                    reject();
                });
        });
    }
};
const mutations = {
    [DIETPLAN_REQUEST]: state => {
        state.status = "loading";
    },
    [DIETPLAN_SUCCESS]: (state, resp) => {
        state.plan = resp.data;
        state.status = "success";
    },
    [DIETPLAN_ERROR]: state => {
        state.status = "error";
        state.statusMessage = "der er sket en fejl...";
    },
    [DIETPLAN_SHUFFLE_REQUEST]: state => {
        state.status = "loading";
    },
    [DIETPLAN_SHUFFLE_SUCCESS]: (state, resp) => {
        state.plan = resp.data;
        state.status = "success";
    },
    [DIETPLAN_SHUFFLE_ERROR]: state => {
        state.status = "error";
        state.statusMessage = "der er sket en fejl...";
    },
    [ADD_RECIPE_REQUEST]: state => { },
    [ADD_RECIPE_SUCCESS]: (state, resp) => {
        state.plan = resp.data;
    },
    [ADD_RECIPE_ERROR]: state => {
        state.statusMessage = "der er sket en fejl...";
    },
    [REMOVE_RECIPE_REQUEST]: state => { },
    [REMOVE_RECIPE_SUCCESS]: (state, resp) => {
        state.plan = resp.data;
    },
    [REMOVE_RECIPE_ERROR]: state => {
        state.statusMessage = "der er sket en fejl...";
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};
