import Vue from "vue";
import Router from "vue-router";
import store from "../store";

import dashboard from "../pages/dashboard";

Vue.use(Router);

let router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "start",
            components: {
                authorized: dashboard
            },
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/login",
            name: "login",
            components: {
                authenticate: () => import(/* webpackChunkName: "login" */ "../pages/login")
            },
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/signup",
            name: "signup",
            components: {
                authenticate: () => import(/* webpackChunkName: "signup" */ "../pages/signup")
            },
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/forgot-password",
            name: "forgotPassword",
            components: {
                authenticate: () => import(/* webpackChunkName: "forgot_password" */ "../components/auth/password/forgot")
            },
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/reset-password",
            name: "resetPassword",
            components: {
                authenticate: () => import(/* webpackChunkName: "reset_password" */ "../components/auth/password/new")
            },
            meta: {
                requiresAuth: false
            }
        },

        {
            path: "/payment/ok",
            components: {
                loading: () => import(/* webpackChunkName: "payment_receipt" */ "../components/subscription/payment_receipt"),
            },
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/feed",
            name: "feed",
            components: {
                authorized: () => import(/* webpackChunkName: "feed" */ "../pages/feed.vue")
            },
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
});
router.beforeResolve((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (store.getters.isAuthenticated) {
            next();
        } else {
            next({ name: "login" });
        }
    }
    else {
        if (store.getters.isAuthenticated) {
            if (to.name == "login") {
                next({ name: "start" });
            }
            else {
                next();
            }
        }
        else {
            next();
        }

    }
});
export default router;
