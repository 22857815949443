export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";

export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const UPLOAD_AVATAR_REQUEST = "UPLOAD_AVATAR_REQUEST";
export const UPLOAD_AVATAR_SUCCESS = "UPLOAD_AVATAR_SUCCESS";
export const UPLOAD_AVATAR_ERROR = "UPLOAD_AVATAR_ERROR";

export const FOLLOW_REQUEST = "FOLLOW_REQUEST";
export const FOLLOW_SUCCESS = "FOLLOW_SUCCESS";
export const FOLLOW_ERROR = "FOLLOW_ERROR";

export const FOLLOWED_USERS_REQUEST = "FOLLOWED_USERS_REQUEST";
export const FOLLOWED_USERS_SUCCESS = "FOLLOWED_USERS_SUCCESS";
export const FOLLOWED_USERS_ERROR = "FOLLOWED_USERS_ERROR";

// ---- edit user ---- //
export const UPDATE_GENERAL_REQUEST = "UPDATE_GENERAL_REQUEST";
export const UPDATE_GENERAL_SUCCESS = "UPDATE_GENERAL_SUCCESS";
export const UPDATE_GENERAL_ERROR = "UPDATE_GENERAL_ERROR";

export const UPDATE_SETTINGS_REQUEST = "UPDATE_SETTINGS_REQUEST";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_ERROR = "UPDATE_SETTINGS_ERROR";

export const UPDATE_GOAL_REQUEST = "UPDATE_GOAL_REQUEST";
export const UPDATE_GOAL_SUCCESS = "UPDATE_GOAL_SUCCESS";
export const UPDATE_GOAL_ERROR = "UPDATE_GOAL_ERROR";

// ---- chat ---- //
export const CHAT_REQUEST = "CHAT_REQUEST";
export const CHAT_SUCCESS = "CHAT_SUCCESS";
export const CHAT_ERROR = "CHAT_ERROR";

export const ADD_CHAT_REQUEST = "ADD_CHAT_REQUEST";

export const ADD_CHAT_SUCCESS = "ADD_CHAT_SUCCESS";
export const ADD_CHAT_ERROR = "ADD_CHAT_ERROR";
export const ADD_SIGNALR_CHAT_MESSAGE = "ADD_SIGNALR_CHAT_MESSAGE";

// ---- mutations ONLY ---- //
export const TOGGLE_PROFILE_DRAWER = "TOGGLE_PROFILE_DRAWER";

export const TOGGLE_PROFILE_EDIT_DRAWER = "TOGGLE_PROFILE_EDIT_DRAWER";

export const TOGGLE_CHAT_DRAWER = "TOGGLE_CHAT_DRAWER";

export const TOGGLE_TOUR_MODAL = "TOGGLE_TOUR_MODAL";

export const TOGGLE_DELETE_ACCOUNT_MODAL = "TOGGLE_DELETE_ACCOUNT_MODAL";

export const TOGGLE_CHANGE_PASSWORD_MODAL = "TOGGLE_CHANGE_PASSWORD_MODAL";

export const TOGGLE_PAYMENT_MODAL = "TOGGLE_PAYMENT_MODAL";
