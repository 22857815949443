import Vue from "vue";
import Vuex from "vuex";
import signup from "./modules/signup";
import auth from "./modules/auth";
import dietplan from "./modules/dietplan";
import recipe from "./modules/recipe";
import tracker from "./modules/tracker";
import feed from "./modules/feed";
import notification from "./modules/notification";
import user from "./modules/user";
import flag from "./modules/flag";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "prod";

export default new Vuex.Store({
  modules: {
    signup,
    auth,
    dietplan,
    recipe,
    tracker,
    feed,
    notification,
    user,
    flag
  },
  strict: debug
});

