const lang = "da";
const inDevelopment = process.env.NODE_ENV == "dev";

import Vue from "vue";
import App from "./App";
import router from "./router";

import store from "./store";

Vue.config.productionTip = inDevelopment;

// ---- localization ---- //
import VueI18n from "vue-i18n";
import { translations } from "./utils/i18n/trans";
import { numbers } from "./utils/i18n/numbers";

// ---- UI ---- //
import ElementUI from 'element-ui';

// ---- Formats ---- //
import VueMoment from "vue-moment";
import moment from "moment";
moment.updateLocale(lang, {
    calendar: {
        lastDay: "[i går]",
        lastWeek: "[i] dddd[s]",
        nextDay: "[i morgen]",
        nextWeek: "på dddd",
        sameDay: "[i dag]",
        sameElse: "Do MMMM YYYY"
    },
    week: {
        dow: 1
    },
});
// ---- Helpers ---- //
import VueMask from "di-vue-mask";
import VueScreen from "vue-screen";
//import VueRx from "vue-rx";

// ---- ServiceWorker ---- //
import wb from "./register_service_worker.js";
Vue.prototype.$workbox = wb;

// ---- bug tracking ---- //
if (!inDevelopment) {
    let _Sentry;
    let _Integrations;
    let _LogLevel;
    Promise.all([
        import("@sentry/browser").then(sentry => {
            _Sentry = sentry;
        }),
        import("@sentry/tracing").then(tracing => {
            _Integrations = tracing.Integrations;
        }),
        import("@aspnet/signalr").then(log => {
            _LogLevel = log.LogLevel;
        })
    ]).then(() => {
        Vue.prototype.$sentry = _Sentry;
        _Sentry.init({
            Vue,
            dsn: "https://7d29be2acc6e4899abca90a48c278ddb@o467735.ingest.sentry.io/5495791",
            integrations: [new _Integrations.BrowserTracing()],
            logLevel: _LogLevel.Error,
            logErrors: true,
            environment: process.env.NODE_ENV,
            ignoreErrors: ['ResizeObserver loop limit exceeded'],
            tracesSampleRate: 0.25
        });
    });
}

Vue.use(ElementUI);
Vue.use(VueI18n);
Vue.use(VueMoment, { moment });
const i18n = new VueI18n({ locale: lang, fallbackLocale: "en", messages: Object.assign(translations), numberFormats: Object.assign(numbers) });
Vue.use(VueMask);
Vue.use(VueScreen, { lg: "1199.98px" });
//Vue.use(VueRx);
import VueTour from 'vue-tour'

Vue.use(VueTour)
Vue.prototype.$vue = Vue;
Vue.mixin({
    methods: {
        isNumber(event) {

            if (!(event.keyCode == 188 || event.keyCode == 190 || event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 48 || event.keyCode == 49 || event.keyCode == 50 || event.keyCode == 51 || event.keyCode == 52 || event.keyCode == 53 || event.keyCode == 54 || event.keyCode == 55 || event.keyCode == 56 || event.keyCode == 57)) {
                event.returnValue = false;
            }
            else {
                let rgxDecimal = /^\d+(\.\d{0,2})?$/;
                let val = event.target.value;
                if (!(event.keyCode == 8)) {
                    let result = val + event.key;
                    if (!rgxDecimal.test(result.replace(/,/g, '.'))) {
                        event.returnValue = false;
                    }
                }
            }


            //console.log(evt)
            //let keyCode = evt.keyCode || evt.which;
            //let key = String.fromCharCode(keyCode);
            //console.log(key)
            //if (key.length == 0) return;
            //let rgx = /^[0-9.,\b]+$/;
            //if (!rgx.test(key)) {
            //    console.log('Fejlede')
            //    evt.returnValue = false;
            //    if (evt.preventDefault) evt.preventDefault();
            //}
            //let rgxDecimal = /^\d+(\.\d{0,2})?$/;
            //let val = evt.target.value;
            //let result = val + key;
            //if (!rgxDecimal.test(result.replace(/,/g, '.'))) {

            //    evt.returnValue = false;
            //    if (evt.preventDefault) evt.preventDefault();
            //}
        }
    },
})
new Vue({
    render: h => h(App),
    router,
    i18n,
    store
}).$mount("#app");
