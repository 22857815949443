//GET
export const RECIPE_REQUEST = "RECIPE_REQUEST";
export const RECIPE_SUCCESS = "RECIPE_SUCCESS";
export const RECIPE_ERROR = "RECIPE_ERROR";

export const ADD_RECIPE_LIKE_REQUEST = "ADD_RECIPE_LIKE_REQUEST";
export const ADD_RECIPE_LIKE_SUCCESS = "ADD_RECIPE_LIKE_SUCCESS";
export const ADD_RECIPE_LIKE_ERROR = "ADD_RECIPE_LIKE_ERROR";

export const RECIPES_REQUEST = "RECIPES_REQUEST";
export const RECIPES_SUCCESS = "RECIPES_SUCCESS";
export const RECIPES_ERROR = "RECIPES_ERROR";

export const RESET_RECIPES = "RESET_RECIPES";

export const GROCERIES_REQUEST = "GROCERIES_REQUEST";
export const GROCERIES_SUCCESS = "GROCERIES_SUCCESS";
export const GROCERIES_ERROR = "GROCERIES_ERROR";

export const REMA_GROCERIES_REQUEST = "REMA_GROCERIES_REQUEST";
export const REMA_GROCERIES_SUCCESS = "REMA_GROCERIES_SUCCESS";
export const REMA_GROCERIES_ERROR = "REMA_GROCERIES_ERROR";

export const FAVOURITES_REQUEST = "FAVOURITES_REQUEST";
export const FAVOURITES_SUCCESS = "FAVOURITES_SUCCESS";
export const FAVOURITES_ERROR = "FAVOURITES_ERROR";

export const ADD_FAVOURITE_REQUEST = "ADD_FAVOURITE_REQUEST";
export const ADD_FAVOURITE_SUCCESS = "ADD_FAVOURITE_SUCCESS";
export const ADD_FAVOURITE_ERROR = "ADD_FAVOURITE_ERROR";

export const REMOVE_FAVOURITE_REQUEST = "REMOVE_FAVOURITE_REQUEST";
export const REMOVE_FAVOURITE_SUCCESS = "REMOVE_FAVOURITE_SUCCESS";
export const REMOVE_FAVOURITE_ERROR = "REMOVE_FAVOURITE_ERROR";

export const LIKES_REQUEST = "LIKES_REQUEST";
export const LIKES_SUCCESS = "LIKES_SUCCESS";
export const LIKES_ERROR = "LIKES_ERROR";

// ---- signalR ---- //
export const ADD_SIGNALR_RECIPE_LIKE = "ADD_SIGNALR_RECIPE_LIKE";

// ---- mutations only ---- //
export const TOGGLE_RECIPES_DRAWER = "TOGGLE_RECIPES_DRAWER";
export const TOGGLE_RECIPE_DRAWER = "TOGGLE_RECIPE_DRAWER";
export const TOGGLE_GROCERIES_DRAWER = "TOGGLE_GROCERIES_DRAWER";
