export const DIETPLAN_REQUEST = "DIETPLAN_REQUEST";
export const DIETPLAN_SUCCESS = "DIETPLAN_SUCCESS";
export const DIETPLAN_ERROR = "DIETPLAN_ERROR";

export const DIETPLAN_SHUFFLE_REQUEST = "DIETPLAN_SHUFFLE_REQUEST";
export const DIETPLAN_SHUFFLE_SUCCESS = "DIETPLAN_SHUFFLE_SUCCESS";
export const DIETPLAN_SHUFFLE_ERROR = "DIETPLAN_SHUFFLE_ERROR";

//POST
export const ADD_RECIPE_REQUEST = "ADD_RECIPE_REQUEST";
export const ADD_RECIPE_SUCCESS = "ADD_RECIPE_SUCCESS";
export const ADD_RECIPE_ERROR = "ADD_RECIPE_ERROR";

export const REMOVE_RECIPE_REQUEST = "REMOVE_RECIPE_REQUEST";
export const REMOVE_RECIPE_SUCCESS = "REMOVE_RECIPE_SUCCESS";
export const REMOVE_RECIPE_ERROR = "REMOVE_RECIPE_ERROR";
