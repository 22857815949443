///* eslint-disable promise/param-names */
import {
    RECIPE_REQUEST,
    RECIPE_SUCCESS,
    RECIPE_ERROR,
    RECIPES_REQUEST,
    RECIPES_SUCCESS,
    RECIPES_ERROR,
    RESET_RECIPES,
    GROCERIES_REQUEST,
    GROCERIES_SUCCESS,
    GROCERIES_ERROR,
    REMA_GROCERIES_REQUEST,
    REMA_GROCERIES_SUCCESS,
    REMA_GROCERIES_ERROR,
    FAVOURITES_REQUEST,
    FAVOURITES_SUCCESS,
    FAVOURITES_ERROR,
    ADD_FAVOURITE_REQUEST,
    ADD_FAVOURITE_SUCCESS,
    ADD_FAVOURITE_ERROR,
    REMOVE_FAVOURITE_REQUEST,
    REMOVE_FAVOURITE_SUCCESS,
    REMOVE_FAVOURITE_ERROR,
    ADD_RECIPE_LIKE_REQUEST,
    ADD_RECIPE_LIKE_SUCCESS,
    ADD_RECIPE_LIKE_ERROR,
    ADD_SIGNALR_RECIPE_LIKE,
    TOGGLE_RECIPES_DRAWER,
    TOGGLE_RECIPE_DRAWER,
    TOGGLE_GROCERIES_DRAWER,
    LIKES_REQUEST,
    LIKES_SUCCESS,
    LIKES_ERROR
} from "../constants/recipe";
import { apiPOST } from "../../utils/api";

const state = {
    all: {
        data: [],
        open: false,
        mealIndex: null,
        dayNo: null,
        calories: null,
        tag: {},
        total: 0,
        status: "",
        statusMessage: ""
    },
    favourites: {
        data: [],
        status: "",
        statusMessage: ""
    },
    single: {
        data: {},
        open: false,
        status: "",
        statusMessage: ""
    },
    groceries: {
        data: {},
        open: false,
        status: "",
        statusMessage: ""
    },
    remaGroceries: {
        data: {},
        status: "",
        statusMessage: ""
    },
    tags: {
        data: [],
        status: "",
        statusMessage: ""
    }
};
const getters = {};
const actions = {
    [RECIPE_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(RECIPE_REQUEST);
            apiPOST("/Recipe/GetById", payload)
                .then(resp => {
                    commit(RECIPE_SUCCESS, resp);
                    resolve();
                })
                .catch(err => {
                    commit(RECIPE_ERROR, err);
                    reject();
                });
        });
    },
    [RECIPES_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(RECIPES_REQUEST);
            apiPOST("/Recipe/GetByUser", payload)
                .then(resp => {
                    commit(RECIPES_SUCCESS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    commit(RECIPES_ERROR, err);
                    reject();
                });
        });
    },
    [ADD_RECIPE_LIKE_REQUEST]: ({ commit, rootState }, payload) => {
        return new Promise((resolve, reject) => {
            apiPOST("/Recipe/Like", payload)
                .then(resp => {
                    commit(ADD_RECIPE_LIKE_SUCCESS, {
                        recipeId: payload,
                        dietPlanRecipes: rootState.dietplan.plan
                    });
                    resolve();
                })
                .catch(err => {
                    commit(ADD_RECIPE_LIKE_ERROR, err);
                    reject();
                });
        });
    },
    [GROCERIES_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(GROCERIES_REQUEST);
            apiPOST("/Recipe/GetShoppinglist", payload)
                .then(resp => {
                    commit(GROCERIES_SUCCESS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    commit(GROCERIES_ERROR, err);
                    reject();
                });
        });
    },
    [REMA_GROCERIES_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(REMA_GROCERIES_REQUEST);
            apiPOST("/Recipe/GetRemaList", payload)
                .then(resp => {
                    commit(REMA_GROCERIES_SUCCESS, resp);
                    console.log(resp)
                    resolve(resp);
                })
                .catch(err => {
                    commit(REMA_GROCERIES_ERROR, err);
                    reject();
                });
        });
    },
    [FAVOURITES_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(FAVOURITES_REQUEST);
            apiPOST("/Recipe/favorites", payload)
                .then(resp => {
                    commit(FAVOURITES_SUCCESS, resp);
                    resolve(resp);
                })
                .catch(err => {
                    commit(FAVOURITES_ERROR, err);
                    reject();
                });
        });
    },
    [ADD_FAVOURITE_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(ADD_FAVOURITE_REQUEST);
            apiPOST("/Recipe/favorite_add", payload)
                .then(resp => {
                    commit(ADD_FAVOURITE_SUCCESS, payload);
                    resolve(resp);
                })
                .catch(err => {
                    commit(ADD_FAVOURITE_ERROR, err);
                    reject();
                });
        });
    },
    [REMOVE_FAVOURITE_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(REMOVE_FAVOURITE_REQUEST);
            apiPOST("/Recipe/favorite_remove", payload)
                .then(resp => {
                    commit(REMOVE_FAVOURITE_SUCCESS, payload);
                    resolve(resp);
                })
                .catch(err => {
                    commit(REMOVE_FAVOURITE_ERROR, err);
                    reject();
                });
        });
    },
    [LIKES_REQUEST]: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            commit(LIKES_REQUEST);
            apiPOST("/Recipe/GetLikes", payload)
                .then(resp => {
                    commit(LIKES_SUCCESS, payload);
                    resolve(resp);
                })
                .catch(err => {
                    commit(LIKES_ERROR, err);
                    reject();
                });
        });
    },
    [ADD_SIGNALR_RECIPE_LIKE]: ({ commit, rootState }, payload) => {
        commit(ADD_SIGNALR_RECIPE_LIKE, {
            recipeId: payload,
            dietPlanRecipes: rootState.dietplan.plan
        });
    }
};
const mutations = {
    [RECIPES_REQUEST]: state => {
        state.all.status = "loading";
    },
    [RECIPES_SUCCESS]: (state, resp) => {
        state.all.status = "success";
        state.tags.data = resp.data.tags;
        state.all.data = state.all.data.concat(resp.data.cards);
        state.all.total = resp.data.total;
    },
    [RECIPES_ERROR]: state => {
        state.all.status = "error";
        state.all.statusMessage =
            "der er sket en ved hentning af opskrifter fejl...";
    },
    [RECIPE_REQUEST]: state => {
        state.single.status = "loading";
    },
    [RECIPE_SUCCESS]: (state, resp) => {
        state.single.status = "success";
        state.single.data = resp.data;
    },
    [RECIPE_ERROR]: (state, resp) => {
        state.single.status = "error";
        state.single.statusMessage =
            "der er sket en ved hentning af en opskrift fejl...";
    },

    [GROCERIES_REQUEST]: state => {
        state.groceries.status = "loading";
    },
    [GROCERIES_SUCCESS]: (state, resp) => {
        state.groceries.status = "success";
        state.groceries.data = resp.data;
    },
    [GROCERIES_ERROR]: state => {
        state.groceries.status = "error";
        state.groceries.data = [];
    },
    [REMA_GROCERIES_REQUEST]: state => {
        state.remaGroceries.status = "loading";
    },
    [REMA_GROCERIES_SUCCESS]: (state, resp) => {
        state.remaGroceries.status = "success";
        state.remaGroceries.data = resp.data;
    },
    [REMA_GROCERIES_ERROR]: state => {
        state.remaGroceries.status = "error";
        state.remaGroceries.data = [];
    },
    [FAVOURITES_REQUEST]: state => {
        state.favourites.status = "loading";
    },
    [FAVOURITES_SUCCESS]: (state, resp) => {
        state.favourites.status = "success";
        state.favourites.data = resp.data;
    },
    [FAVOURITES_ERROR]: state => {
        state.favourites.status = "error";
        state.favourites.statusMessage = "der er sket en fejl...";
    },
    [ADD_FAVOURITE_REQUEST]: state => {
        state.favourites.status = "loading";
    },
    [ADD_FAVOURITE_SUCCESS]: (state, resp) => {
        let recipe = state.all.data.find(x => x.id === resp.recipeId);
        if (typeof recipe !== "undefined") {
            recipe.isFavorite = true;
            state.favourites.data.push(recipe);
        }

        if (state.single.open) {
            if (state.single.data.id === resp.recipeId) {
                console.log(state.single.data);
                state.single.data.isFavorite = true;
            }
        }
        state.favourites.status = "success";
    },
    [ADD_FAVOURITE_ERROR]: state => {
        state.favourites.status = "error";
        state.favourites.statusMessage = "der er sket en fejl...";
    },
    [REMOVE_FAVOURITE_REQUEST]: state => {
        state.favourites.status = "loading";
    },
    [REMOVE_FAVOURITE_SUCCESS]: (state, resp) => {
        let recipe = state.all.data.find(x => x.id === resp.recipeId);
        let favRecipeIndex = state.favourites.data.findIndex(
            x => x.id === resp.recipeId
        );

        if (typeof recipe !== "undefined") {
            recipe.isFavorite = false;
        }
        if (typeof favRecipeIndex !== "undefined") {
            state.favourites.data.splice(favRecipeIndex, 1);
        }
        state.favourites.status = "success";
    },
    [REMOVE_FAVOURITE_ERROR]: state => {
        state.favourites.status = "error";
        state.favourites.statusMessage = "der er sket en fejl...";
    },
    [ADD_RECIPE_LIKE_SUCCESS]: (state, resp) => {
        return new Promise(function (resolve, reject) {
            let dietplanRecipe = resp.dietPlanRecipes.find(
                x => x.card != null && x.card.id == resp.recipeId
            );

            if (typeof dietplanRecipe !== "undefined") {
                if (dietplanRecipe.card.isLiked) {
                    dietplanRecipe.card.isLiked = false;
                } else {
                    dietplanRecipe.card.isLiked = true;
                }
            }
            if (state.all.data.length != 0) {
                let recipe = state.all.data.find(x => x.id === resp.recipeId);
                if (recipe.isLiked) {
                    recipe.isLiked = false;
                } else {
                    recipe.isLiked = true;
                }
            }
            if (state.single.open) {
                if (state.single.data.id === resp.recipeId) {
                    if (state.single.data.isLiked) {
                        state.single.data.isLiked = false;
                    } else {
                        state.single.data.isLiked = true;
                    }
                }
            }
        });
    },
    [ADD_RECIPE_LIKE_ERROR]: (state, resp) => { },

    [ADD_SIGNALR_RECIPE_LIKE]: (state, resp) => {
        return new Promise(function (resolve, reject) {
            let dietplanRecipe = resp.dietPlanRecipes.find(
                x => x.card != null && x.card.id == resp.recipeId.recipeId
            );
            if (typeof dietplanRecipe !== "undefined") {
                dietplanRecipe.card.likes = resp.recipeId.likes;
            }
            if (state.all.data.length != 0) {
                let recipe = state.all.data.find(x => x.id === resp.recipeId.recipeId);
                recipe.likes = resp.recipeId.likes;
            }
            if (state.single.open) {
                if (state.single.data.id === resp.recipeId.recipeId) {
                    state.single.data.likes = resp.recipeId.likes;
                }
            }
        });
    },
    [LIKES_REQUEST]: (state, resp) => { },
    [LIKES_SUCCESS]: (state, resp) => { },
    [LIKES_ERROR]: (state, resp) => { },

    [RESET_RECIPES]: state => {
        state.all.data = [];
        state.all.total = 0;
    },
    // ---- drawers ---- //
    [TOGGLE_RECIPES_DRAWER]: (state, resp) => {
        if (state.all.open) {
            state.all.mealIndex = null;
            state.all.total = 0;
            state.all.open = false;
        } else {
            state.all.mealIndex = resp.index;
            state.all.dayNo = resp.dayNo;
            state.all.tag = resp.tag;
            state.all.calories = resp.calories;
            state.all.open = true;
        }
    },
    [TOGGLE_RECIPE_DRAWER]: state =>
        state.single.open
            ? (state.single.open = false)
            : (state.single.open = true),
    [TOGGLE_GROCERIES_DRAWER]: state =>
        state.groceries.open
            ? (state.groceries.open = false)
            : (state.groceries.open = true)
};
export default {
    state,
    getters,
    actions,
    mutations
};
