export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";

export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const CLOSE_ACCOUNT_REQUEST = "CLOSE_ACCOUNT_REQUEST";
export const CLOSE_ACCOUNT_SUCCESS = "CLOSE_ACCOUNT_SUCCESS";
export const CLOSE_ACCOUNT_ERROR = "CLOSE_ACCOUNT_ERROR";

export const SUBSCRIPTION_REQUEST = "SUBSCRIPTION_REQUEST";
export const SUBSCRIPTION_SUCCESS = "SUBSCRIPTION_SUCCESS";
export const SUBSCRIPTION_ERROR = "SUBSCRIPTION_ERROR";

export const SUBSCRIPTION_REACTIVATE_REQUEST = "SUBSCRIPTION_REACTIVATE_REQUEST";
export const SUBSCRIPTION_REACTIVATE_SUCCESS = "SUBSCRIPTION_REACTIVATE_SUCCESS";
export const SUBSCRIPTION_REACTIVATE_ERROR = "SUBSCRIPTION_REACTIVATE_ERROR";

export const CLOSE_SUBSCRIPTION_REQUEST = "CLOSE_SUBSCRIPTION_REQUEST";
export const CLOSE_SUBSCRIPTION_SUCCESS = "CLOSE_SUBSCRIPTION_SUCCESS";
export const CLOSE_SUBSCRIPTION_ERROR = "CLOSE_SUBSCRIPTION_ERROR";

export const SET_STATE_TOKEN = "SET_STATE_TOKEN";
